import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import BlogList from "./BlogList";
import Blog from "./Blog";
import CreateArticleButton from "../../blog/Components/CreateArticleButton";
import * as Icon from "./Icon";

const UI_STATE = {
  LIST: "LIST",
  DETAIL: "DETAIL",
};

class BlogSystem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      uiState: UI_STATE.LIST,
      articleId: null,
    };
  }

  render() {
    let { user, isEditor = false } = this.props;
    let { uiState, articleId } = this.state;

    return (
      <>
        {uiState === UI_STATE.LIST ? (
          <Wrapper>
            <div className="header-container">
              <h2>文章列表</h2>

              {isEditor && (
                <CreateArticleButton token={user.token}>
                  {({ createArticle }) => (
                    <div
                      className="create-article-btn"
                      onClick={async () => {
                        let newArticle = await createArticle();
                        this.setState({
                          articleId: newArticle.id,
                          uiState: UI_STATE.DETAIL,
                        });
                      }}
                    >
                      新增文章+
                    </div>
                  )}
                </CreateArticleButton>
              )}
            </div>
            <BlogList
              onItemClick={id => {
                this.setState({
                  articleId: id,
                  uiState: UI_STATE.DETAIL,
                });
              }}
            />
          </Wrapper>
        ) : (
          <Wrapper>
            <div
              style={{ cursor: "pointer", padding: 10, paddingLeft: 0 }}
              onClick={() =>
                this.setState({ uiState: UI_STATE.LIST, articleId: null })
              }
            >
              <Icon.ArrowBack size={25} color={"#444"} />
            </div>
            <Blog
              isEditor={isEditor}
              articleId={articleId}
              onBack={() =>
                this.setState({ uiState: UI_STATE.LIST, articleId: null })
              }
            />
          </Wrapper>
        )}
      </>
    );
  }
}

const Wrapper = styled.div`
  & > .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    & > h2 {
      font-size: 26px;
      color: #444;
      margin-bottom: 10px;
    }

    & .create-article-btn {
      padding: 10px 16px;
      color: #444;
      background-color: aquamarine;
      cursor: pointer;
      border-radius: 3px;
      text-align: center;
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    user: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(BlogSystem);
