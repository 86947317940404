import React, { Component } from "react";
import styled from "styled-components";
import { withBlogContext } from "../BlogContext";

class CreateArticleButton extends Component {
  render() {
    let { children, contextReady, token } = this.props;
    if (!token) {
      console.error("rev-cms-core: CreateArticleButton: MUST HAVE token");
    }

    return children({
      isInitializing: !contextReady,
      createArticle: this._createArticle,
      contextReady,
    });
  }

  _createArticle = async () => {
    let {
      contextReady,
      token,
      value: { articleApi },
    } = this.props;
    if (contextReady) {
      let article = articleApi.createArticle(token);
      return article;
    }
    return Promise.reject("blog context not ready");
  };
}

export default withBlogContext(CreateArticleButton);
