import React from "react";
import styled from "styled-components";
import { withPage } from "./PageContainer";
import { connect } from "react-redux";
import Selectors from "./Selectors";
import ActionCreator from "./ActionCreator";
import LoginPanel from "./Components/LoginPanel";
import Dashboard from "./Components/Dashboard";
import BlogSystem from "./Components/BlogSystem";
import * as Blog from "../blog/BlogContext";
import * as Icon from "./Components/Icon";
import BlogDetail from "./Components/Blog";

const ServiceClients = require("./ServiceClients");

class LandingPage extends React.Component {
  render() {
    let { user, id } = this.props;

    if (!user) {
      return (
        <Wrapper>
          <LoginPanel style={{ display: "flex", alignItems: "center" }} />
        </Wrapper>
      );
    }

    const clientConfig = ServiceClients[user.app_name];

    if (!clientConfig) {
      return (
        <div style={{ padding: 10 }}>
          <h3>{`No such client: ${user.app_name}`}</h3>
        </div>
      );
    }

    return (
      <Dashboard
        themeColor={"orange"}
        Header={
          clientConfig.logoImage ? (
            <Logo src={clientConfig.logoImage} />
          ) : (
            <h3>
              {clientConfig
                ? `${clientConfig.display || clientConfig.key}`
                : `Revtel Blog`}
            </h3>
          )
        }
        entries={[
          {
            name: "articles-view",
            label: "文章列表",
            icon: <Icon.UnorderedListOutlined color="white" />,
          },
          {
            name: "logout",
            label: "登出",
            type: "function",
            icon: <Icon.LogoutOutlined color="white" />,
          },
        ]}
        entryFunctionHandler={this._onEntryFunction}
      >
        {({ cms }) => {
          let { entry } = cms;
          if (entry.name === "articles-view") {
            return (
              <ContentWrapper>
                <BlogSystem key={"articles-view"} isEditor={true} />
              </ContentWrapper>
            );
          }
          return (
            <div style={{ padding: 20 }}>
              <h1>{entry.name}</h1>
            </div>
          );
        }}
      </Dashboard>
    );
  }

  _onEntryFunction = entry => {
    let { appActions } = this.props;
    if (entry.name === "logout") {
      appActions.logout();
    }
  };
}

const Wrapper = styled.div`
  padding: 20px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding: 20px;
`;

const Logo = styled.div`
  border-radius: 10px;
  width: 80%;
  min-height: 80px;
  margin: 10px auto;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      user: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(LandingPage)
);
