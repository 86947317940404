import React, { Fragment } from "react";
import styled from "styled-components";
import { Layout, Menu, Breadcrumb } from "antd";
import * as Ant from "antd";
import * as Icon from "./Icon";

const { Header: AntHeader, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class CmsDashboard extends React.Component {
  static defaultProps = {
    Header: "Revtel Admin",
    entryFunctionHandler: () => 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      length: this.props.entries.length,
      selectedEntry: this.props.entries[0],
      rwdSidebarOpen: false,
      collapsed: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.entries.length !== prevState.length) {
      return {
        length: nextProps.entries.length,
        selectedEntry: nextProps.entries[0],
      };
    }
    return null;
  }

  render() {
    let { entries, themeColor, onSelectEntry } = this.props;
    let { selectedEntry, rwdSidebarOpen } = this.state;

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={collapsed => this.setState({ collapsed })}
        >
          {this._renderHeader()}
          <Menu
            theme="dark"
            defaultSelectedKeys={[selectedEntry.name]}
            mode="inline"
          >
            {entries.map(entry => {
              if (entry.entries && entry.entries.length > 0) {
                return (
                  <SubMenu key={entry.name} title={<span>{entry.label}</span>}>
                    {entry.entries.map(e => (
                      <Menu.Item
                        key={entry.name}
                        onClick={() => this._onSelectEntry(e)}
                      >
                        <span>{entry.label}</span>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              }

              return (
                <Menu.Item
                  key={entry.name}
                  onClick={() => this._onSelectEntry(entry)}
                >
                  {entry.icon || null}
                  <span>{entry.label}</span>
                </Menu.Item>
              );
            })}
          </Menu>
          {/* // <CmsEntry
            //   key={entry.name}
            //   entry={entry}
            //   selectedEntry={selectedEntry}
            //   level={1}
            //   onSelectEntry={this._onSelectEntry}
            // />           */}
        </Sider>
        <Layout>
          <AntHeader style={{ backgroundColor: "white" }}>
            <Ant.Row justify="end" gutter={32}>
              <Ant.Col flex="auto">
                <h2 style={{ letterSpacing: 2 }}>{selectedEntry.label}</h2>
              </Ant.Col>
              <Ant.Col span={1}>
                <Icon.SearchOutlined />
              </Ant.Col>
              <Ant.Col span={1}>
                <Icon.BellOutlined />
              </Ant.Col>
              <Ant.Col span={6}>
                <Ant.Row align="middle" justify="end">
                  <Avatar />
                  <div style={{ marginLeft: 10 }}>管理員</div>
                </Ant.Row>
              </Ant.Col>
            </Ant.Row>
          </AntHeader>
          <Content>
            <Breadcrumb>
              <Breadcrumb.Item></Breadcrumb.Item>
            </Breadcrumb>
            {this._renderContent()}
          </Content>
        </Layout>
      </Layout>

      // <Wrapper themeColor={themeColor} rwdSidebarOpen={rwdSidebarOpen}>
      //   <div className="admin-sidebar">
      //     {this._renderHeader()}

      //     {entries.map(entry => (
      //       <CmsEntry
      //         key={entry.name}
      //         entry={entry}
      //         selectedEntry={selectedEntry}
      //         level={1}
      //         onSelectEntry={this._onSelectEntry}
      //       />
      //     ))}
      //   </div>

      //   <div className="admin-tab-content">{this._renderContent()}</div>

      //   <div className="admin-rwd-toggle" onClick={this._toggleRwdSidebar}>
      //     {"\u2630"}
      //   </div>
      // </Wrapper>
    );
  }

  _renderHeader = () => {
    let { Header } = this.props;
    let { selectedEntry } = this.state;

    if (typeof Header === "string") {
      return <div className="site-title">Revtel Admin</div>;
    } else if (typeof Header === "function") {
      // Component
      return <Header selectedEntry={selectedEntry} />;
    } else if (typeof Header === "object") {
      // Element
      return Header;
    }
  };

  _renderContent = () => {
    let { selectedEntry } = this.state;
    try {
      return this.props.children({
        cms: {
          entry: selectedEntry,
        },
      });
    } catch (ex) {
      return <div>{selectedEntry.name}</div>;
    }
  };

  _onSelectEntry = entry => {
    let { entryFunctionHandler } = this.props;
    if (entry.type === "function") {
      entryFunctionHandler(entry);
    } else {
      this.setState({ selectedEntry: entry, rwdSidebarOpen: false });
    }
  };

  _toggleRwdSidebar = () => {
    let { rwdSidebarOpen } = this.state;
    this.setState({ rwdSidebarOpen: !rwdSidebarOpen });
  };
}

class CmsEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
    };
  }

  render() {
    let { entry, level, selectedEntry, onSelectEntry } = this.props;
    let { expand } = this.state;
    let isSelected = selectedEntry.name === entry.name;

    return (
      <>
        <Menu.Item key={entry.name} onClick={this._onEntryClick}>
          <span>{entry.label}</span>
        </Menu.Item>
        {entry.entries && entry.entries.length > 0 && (
          <SubMenu>
            {entry.entries.map(e => (
              <CmsEntry
                level={level + 1}
                entry={e}
                selectedEntry={selectedEntry}
                onSelectEntry={onSelectEntry}
                key={e.name}
              />
            ))}
            }
          </SubMenu>
        )}
        {/* <div
          className={
            "entry " + `level${level} ` + (isSelected ? "selected" : "")
          }
          onClick={this._onEntryClick}
        >
          <div className="label">{entry.label}</div>

          {entry.entries && <div className="expand">{expand ? "-" : "+"}</div>}
        </div>

        {expand &&
          entry.entries.map(e => (
            <CmsEntry
              level={level + 1}
              entry={e}
              selectedEntry={selectedEntry}
              onSelectEntry={onSelectEntry}
              key={e.name}
            />
          ))} */}
      </>
    );
  }

  _onEntryClick = () => {
    let { entry, onSelectEntry } = this.props;
    let { expand } = this.state;

    if (entry.entries) {
      this.setState({ expand: !expand });
    } else {
      onSelectEntry(entry);
    }
  };
}

const globalThemeColor = "#eee";
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;

  & > .admin-rwd-toggle {
    display: none;

    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      right: 20px;
      bottom: 20px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      cursor: pointer;
      background-color: ${props => props.themeColor || globalThemeColor};
    }
  }

  & > .admin-sidebar {
    @media screen and (max-width: 600px) {
      position: fixed;
      height: 100%;
      left: ${props => (props.rwdSidebarOpen ? "0px" : "-200px")};
      transition: left 200ms;
    }

    width: 200px;
    flex-shrink: 0;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    background-color: white;
    border-left: 10px solid ${props => props.themeColor || globalThemeColor};
    z-index: 1;
    overflow-y: scroll;

    .site-title {
      padding: 10px;
      font-weight: bold;
      color: ${props => props.themeColor || globalThemeColor};
    }

    .entry {
      padding: 15px 18px;
      display: flex;
      cursor: pointer;
    }

    .entry .label {
      flex: 1;
    }

    .entry.selected {
      color: white;
      background-color: ${props => props.themeColor || globalThemeColor};
    }

    .entry.level2 {
      padding-left: 25px;
    }

    .entry:hover {
      color: white;
      background-color: ${props => props.themeColor || globalThemeColor};
      cursor: pointer;
    }
  }

  & > .admin-tab-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow-y: auto;
    background-color: #f9f9f9;
  }
`;

const Avatar = styled.div`
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background-color: #aaa;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
`;

export default CmsDashboard;
