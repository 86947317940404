import React, { Component } from "react";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import Selectors from "../Selectors";
import ApiArticle from "../../blog/ApiArticle";
import constants from "../constants";
import { dateFormat } from "../Utils/DateUtil";
import * as Ant from "antd";
import * as Icon from "./Icon";

const PAGE_SIZE = 10;

class BlogList extends Component {
  constructor(props) {
    super(props);
    this._api = ApiArticle({ apiHost: constants.serviceBlogApi });
    this.state = {
      loading: true,
      articles: [],
      pagination: null,
      searchLabel: "",
    };
  }

  async componentDidMount() {
    try {
      await this._fetchFirstPage();
    } catch (ex) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { user, appActions, onItemClick } = this.props;
    const { loading, pagination, articles } = this.state;

    const dataSource = articles.map(item => ({
      ...item,
      key: item.id,
    }));

    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (text, record) => (
          <a onClick={() => onItemClick(record.id)}>{text || "untitled"}</a>
        ),
      },
      {
        title: "Label",
        dataIndex: "label",
        key: "label",
      },
      {
        title: "Created",
        dataIndex: "updated",
        key: "updated",
        render: text => dateFormat(text, "MMM DD, YYYY"),
      },
      {
        title: "Owner",
        dataIndex: "owner",
        key: "owner",
      },
    ];

    return (
      <Wrapper>
        <div style={{ marginBottom: 10 }}>
          <Ant.Input.Search
            placeholder="Label"
            enterButton="Search"
            onSearch={this._handleSearchLabel}
          />
        </div>

        <Ant.Table
          dataSource={dataSource}
          loading={loading}
          columns={columns}
          pagination={pagination}
          onChange={this._handleTableChange}
        />
      </Wrapper>
    );
  }

  _handleSearchLabel = searchLabel => {
    this.setState({ searchLabel }, () => {
      this._fetchFirstPage();
    });
  };

  _handleTableChange = async (pagination, filters, sorter) => {
    this._fetchPage({ pagination });
  };

  _fetchPage = async ({ pagination }) => {
    const { user } = this.props;
    const { searchLabel } = this.state;
    const { current, pageSize } = pagination;

    this.setState({ loading: true });

    try {
      const articles = await this._api.getArticles({
        project: user.app_name,
        offset: (current - 1) * pageSize,
        limit: pageSize,
        label: searchLabel,
        sort: "-updated",
      });

      this.setState({
        articles,
        pagination,
      });
    } catch (ex) {
      //bypass
    }

    this.setState({ loading: false });
  };

  _fetchFirstPage = async () => {
    const { user } = this.props;
    const { searchLabel } = this.state;
    const extraFilters = searchLabel ? { label: searchLabel } : {};
    const { count: total } = await this._api.countArticles({
      project: user.app_name,
      ...extraFilters,
    });

    await this._fetchPage({
      pagination: {
        current: 1,
        pageSize: PAGE_SIZE,
        total,
      },
    });
  };
}

const Wrapper = styled.div`
  .spinner-wrapper {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
`;

const ArticleItem = styled.div`
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 5px;
  background-color: white;

  & .update-time {
    font-size: 14px;
    color: grey;
  }
`;

export default connect(
  (state, ownProps) => ({
    user: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(BlogList);
