import React, { Component } from "react";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Icon from "./Icon";
import * as Ant from "antd";
import Theme from "../Theme";

const ERROR = {
  LOGIN_FAIL: "LOGIN_FAIL",
};

class LoginPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: "",
      password: "",
      project: "",
      error: null,
      loading: false,
    };
  }

  render() {
    let { account, password, project, error, loading } = this.state;
    let { style } = this.props;

    return (
      <Wrapper style={style}>
        <Ant.Row gutter={[0, 16]} justify="center">
          <Ant.Col span={4} style={{ textAlign: "center" }}>
            <img src={require("../images/revtel-icon.png")} width="40" />
          </Ant.Col>
          <Ant.Col span={20}>
            <h1 style={{ color: Theme.colorDark }}>Revtel Tech Blog System</h1>
          </Ant.Col>
          <Ant.Col span={24} style={{ textAlign: "center" }}>
            <h6 style={{ color: Theme.colorGrey }}>
              Revtel Tech Blog System is best partner for bloggggger.
            </h6>
          </Ant.Col>
          <Ant.Col span={24} style={{ textAlign: "center" }}>
            <div style={{ color: Theme.colorDarkGrey }}>選擇登入通道</div>
          </Ant.Col>

          <Ant.Col span={24}>
            <Ant.Input
              size="large"
              placeholder="專案"
              name="project"
              value={project}
              onChange={e =>
                this.setState({
                  project: e.target.value,
                })
              }
              prefix={<Icon.HomeOutlined color={Theme.colorLightGrey} />}
            />
          </Ant.Col>

          <Ant.Col span={24}>
            <Ant.Input
              size="large"
              placeholder="帳號"
              name="account"
              value={account}
              onChange={e =>
                this.setState({
                  account: e.target.value,
                })
              }
              prefix={<Icon.UserOutlined color={Theme.colorLightGrey} />}
            />
          </Ant.Col>

          <Ant.Col span={24}>
            <Ant.Input.Password
              size="large"
              placeholder="密碼"
              name="password"
              value={password}
              onChange={e =>
                this.setState({
                  password: e.target.value,
                })
              }
              prefix={<Icon.LockOutlined color={Theme.colorLightGrey} />}
            />
          </Ant.Col>

          <Ant.Col span={24}>
            <Ant.Button
              type="primary"
              block
              onClick={this._onLogin}
              loading={loading}
            >
              登入
            </Ant.Button>
          </Ant.Col>
          {error && error === ERROR.LOGIN_FAIL && (
            <Ant.Alert
              message={"登入失敗！請確認所選專案名稱與帳號密碼正確！"}
              type="error"
            />
          )}
        </Ant.Row>
      </Wrapper>
    );
  }

  _onLogin = async () => {
    let { account, password, project } = this.state;
    let { appActions } = this.props;

    this.setState({ loading: true });
    try {
      await appActions.login({ account, password }, project);
    } catch (err) {
      this.setState({ error: ERROR.LOGIN_FAIL });
    }
    this.setState({ loading: false });
  };
}

const Wrapper = styled.div`
  width: 400px;
  margin: 0 auto;
  & .project-form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    & .project-form {
      min-width: 260px;
      padding: 20px 0px;
      .project-item {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        cursor: pointer;
        margin-bottom: 5px;
      }
    }
  }

  & .login-form {
    display: flex;
    margin-bottom: 150px;
    align-items: center;
    flex-direction: column;

    input {
      width: 280px;
      height: 38px;
      margin: 10px;
      border-radius: 6px;
      outline: none;
      border: 1px solid #95a7b1;
      padding: 7px 12px;
    }
  }

  & .login-button {
    padding: 10px 15px;
    text-align: center;
    cursor: pointer;
    background-color: blue;
    color: white;
  }

  & .error-text {
    color: tomato;
    padding: 20px 10px;
  }
`;

const Card = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: solid 2px
    ${props => (props.active ? Theme.colorLink : Theme.colorBackground)};
  height: 100%;
  width: 100%;
  min-height: 100px;
  object-fit: contain;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  background-clip: padding-box;

  &:hover {
    box-shadow: 0 9px 10px -5px #888;
    transition: all 0.2s linear;
    cursor: pointer;
  }
`;

export default connect((state, ownProps) => ({}), ActionCreator)(LoginPanel);
